import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Lang } from '../lang.type';

/**
 * Sets Correct language in <html lang={}> tag
 */
@Injectable({
    providedIn: 'root',
})
export class I18nHtmlLangService {
    private readonly document = inject(DOCUMENT);

    /**
     * Sets correct HTML Doc language
     */
    setLang(lang: Lang): void {
        const ln = lang.split('-')[0];
        this.document.documentElement.lang = ln;
    }
}
