import { ChangePasswordValue } from './change-password.value';

class ChangePasswordValueDto {
    current_password: string;
    password: string;

    constructor(data: ChangePasswordValue) {
        this.current_password = data.currentPassword;
        this.password = data.newPassword;
    }
}

export { ChangePasswordValueDto };
