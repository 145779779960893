import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { filter, map, take, tap } from 'rxjs';
import { UserFacade } from '../facades/user.facade';

export function userLoaded(): CanActivateFn {
    return () => {
        const userFacade = inject(UserFacade);

        return userFacade.loaded$.pipe(
            tap((loaded) => {
                if (loaded === null) {
                    userFacade.getUser();
                }
            }),
            filter((loaded) => loaded !== null),
            take(1),
            map(() => true),
        );
    };
}
