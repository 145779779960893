import { Observable } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';

import * as plPL from '../../../translations/pl-PL.json';
import * as enEn from '../../../translations/en-EN.json';
import { Lang } from '../lang.type';

export class TranslateLoaderStatic implements TranslateLoader {
    getTranslation(lang: Lang): Observable<{ [key: string]: string }> {
        return new Observable((observer) => {
            switch (lang) {
                case 'pl-PL':
                    observer.next((<unknown>plPL) as { [key: string]: string });
                    break;

                case 'en-EN':
                    observer.next((<unknown>enEn) as { [key: string]: string });
                    break;
            }
            observer.complete();
        });
    }
}
